
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    gameURL: {
      type: String,
      default: 'https://philip-morris-germany.leadfamly.com/iqos-birthday',
    },
  },
  setup() {
    const store = useStore();

    const userId = store.getters['authModule/userId'];
    const {
      headers: { 'x-login-token': token },
    } = store.getters['authModule/authHeaders'];

    return {
      token,
      userId,
    };
  },
});
